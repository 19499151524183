<template>
  Wagenfeld ist erst 23 Jahre alt, als er im Oktober 1923 ans Bauhaus nach Weimar kommt.
  Für ihn war es „ein Sprung in eine ganz fremde Welt“, wie er später sagt.
  Da er bereits eine Ausbildung als Industriezeichner hat, muss er nicht den sog.
  „Vorkurs“ besuchen, sondern darf gleich in der Metallwerkstatt arbeiten.
  Hier werden klassische Gegenstände wie Kerzenleuchter, Teekannen oder Schmuckstücke gefertigt, aber es gibt auch Experimente mit ganz neuen Design-Aufgaben, wie z.B. elektrische Leuchten.
  Bereits 6 Monaten nach seiner Ankunft in Weimar entwirft Wagenfeld dann die Leuchte, die als „Bauhausleuchte“ berühmt geworden ist.
</template>

<script>
export default {
name: "Text0",
}
</script>

<style scoped>
 .rotation{
   height: 1rem;
   width: 1rem;
 }
</style>