<template>
<!--die IDs undso müssen noch in den Daten festgelegt werden damit es keine komischen verschiebungen gibt aber für den Prototypen sollte das so reichen
-->
  <iframe id="arjs-cell" src="vr.html">
  <!-- we add detectionMode and matrixCodeType to tell AR.js to recognize barcode markers -->
  <a-scene embedded vr-mode-ui="enabled: false" gltf-model="dracoDecoderPath: https://www.gstatic.com/draco/v1/decoders/;" arjs="sourceType: webcam; debugUIEnabled: false; detectionMode: mono_and_matrix; matrixCodeType: 3x3;"
           renderer="antialias: true;
                   colorManagement: true;
                   sortObjects: true;
                   physicallyCorrectLights: true;
                   maxCanvasWidth: {{maxWidth}};
                   maxCanvasHeight: {{maxHeight}};">

    <a-assets>
      <!-- <a-asset-item id="animated-asset" src="https://raw.githubusercontent.com/nicolocarpignoli/nicolocarpignoli.github.io/master/ar-playground/models/CesiumMan.gltf"></a-asset-item> -->
      <a-asset-item id="dingding" src="dingding2.gltf"></a-asset-item>
      <a-asset-item id="lamp" src="Lamp.gltf"></a-asset-item>
    </a-assets>

    <a-marker type="pattern" preset="custom" url="wwh_graphic.patt">
      <a-entity
          gltf-model="#lamp"
          scale="1 1 1">
      </a-entity>
    </a-marker>

    <a-marker type='barcode' value='6'>
      <a-entity
          animation-mixer
          gltf-model="#lamp"
          position="0 0 0.5"
          rotation="-90 0 0"
          scale="0.01 0.01 0.01">
      </a-entity>
    </a-marker>

    <a-marker type='barcode' value='7'>
      <a-entity
          gltf-model="#lamp"
          position="0 0 0"
          scale="0.01 0.01 0.01">
      </a-entity>
    </a-marker>

    <a-entity camera embedded></a-entity>
  </a-scene>
  </iframe>

<!-- Re enable this for the final prod to use compressed models gltf-model="dracoDecoderPath: https://www.gstatic.com/draco/v1/decoders/;"
  <a-scene embedded vr-mode-ui="enabled: false"  arjs="sourceType: webcam; debugUIEnabled: false; detectionMode: mono_and_matrix; matrixCodeType: 3x3;">
  <a-assets>
    <a-asset-item v-for="object in allData" :key="object.id" :id="object.id" :src="'/model/' + object.modelname"></a-asset-item>
  </a-assets>
  <a-marker v-for="object in allData" :key="object.id + 'marker'" type="barcode" :value="object.id">
    <a-entity
        animation-mixer
        :gltf-model="object.id"
        position="0 0 0.5"
        rotation="-90 0 0"
        scale="0.01 0.01 0.01">
    </a-entity>
  </a-marker>
    <a-entity camera></a-entity>
  </a-scene>-->
</template>

<script>

export default {
name: "Ar",
  props:{
  allData: Object,
  },
  computed: {
    maxWidth: {
      get: function(){
        return window.innerWidth
      }
    },
    maxHeight: {
      get: function(){
        return window.innerHeight
      }
    },
  },
/*  mounted() {
    this.THREEx = THREEx || {};
    this.THREEx.wrapperElement = null;
    this.THREEx.setWrapperElement = function (wrapperEl, video)
  },*/
}
</script>

<style scoped>
  #arjs-cell{
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    border: none;
  }
</style>