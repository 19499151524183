<template>
  <InfoView @rotateTo="rotateTo" @toggleInfo="toggleView" :infoViewP="infoView" :modelData="currentModel" :currentModel="currentModelTest" ></InfoView>
  <ObjectView
      @forceRotationDone="resetForceRotation"
      :infoView="infoView" :forceRotatingP="forceRotating" :forceRotationO="forceRotationO" :forceRotationX="forceRotationX" :forceRotationY="forceRotationY" :forceRotationZ="forceRotationZ"></ObjectView>

</template>

<script>
import ObjectView from "../components/ObjectView";
import InfoView from "../components/InfoView";
export default {
  name: "Home",
  components: {
    InfoView,
    ObjectView,
  },
  props: {
    modelData: Object,
    allData: Object,

  },
  data(){
    return{
      infoView: false,
      currentModel: null,
      forceRotationX: 0,
      forceRotationY: 1,
      forceRotationZ: 5.6820125634939593,
      forceRotationO: 1,
      forceRotating:false,

    }
  },
  computed: {
    currentModelTest: {
      get: function () {
        return this.currentModel;
      },
      set: function (id){
        this.modelData.forEach((obj) => {
          if(obj.id === id){
            this.currentModel = obj;
          }
        })
      }
    },
  },
  methods: {
    toggleView(){
      this.infoView = !this.infoView;
    },
    rotateTo(x ,y ,z, o){
      this.forceRotationX = x;
      this.forceRotationY = y;
      this.forceRotationZ = z;
      this.forceRotationO = o;
      this.forceRotating = true;
    },
    resetForceRotation(){
      this.forceRotating = false;
      this.forceRotationX = 0;
      this.forceRotationY = 0;
      this.forceRotationZ = 0;
      this.forceRotationO = 0;
    }
  },
  mounted() {
    this.currentModelTest = 2;
  }
};
</script>
