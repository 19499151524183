<template>
  <div  class="icon__infoSwitch" v-bind:infoViewP="infoViewP"  >
    <div :class="{currentInfo : infoViewP}">
    <InfoButton @click="$emit('toggleInfo')" class="infoButton" size="100%"></InfoButton>
    </div>
  </div>
  <div class="info__window"   :class="[infoViewP ? 'active' : 'notActive' ]" >
    <div v-if="modelData != null" class="info__text" @touchstart="doStartDrag" @touchmove="doDrag($event)" @touchend="doStopTouch($event)" :class="[infoViewP ? 'active' : 'notActive' ]" v-show="infoViewP">
      <h1>{{ modelData.name }}</h1>
<!--      <h2>{{ modelData.subtitle}}</h2>-->
      <div id="topics" class="topics">
<!--        <p class="secondary">
          {{previousHeader}}
        </p>
        <p class="current">
          {{currentHeader}}
        </p>
        <p class="secondary">
          {{nextHeader}}
        </p>-->
<!--        <p v-for="header in modelData.texts" :key="header.id"
           :class="{current : header.id === currentTopicId,
            secondary: header.id === nextHeader || header.id === previousHeader,
             inactive : header.id !== currentTopicId && header.id !== currentTopicId + 1 && header.id !== currentTopicId - 1}">
          {{header.header}}
        </p>-->
        <div id="previous" @click="currentTopic  = currentTopicId -1"></div>
        <p id="current" class="current">
          {{currentHeader}}
        </p>
        <div id="next" @click="currentTopic  = currentTopicId +1"></div>
      </div>
      <div class="category__visualisation">
      <div v-for="header in modelData.texts" :key="header.id" :class="{current__visual : header.id === currentTopicId}"> &nbsp;</div>
      </div>
        <div class="scroll">
        <div class="currentText">
          <Text0 @rotateTo="rotateTo" v-if="currentTopicIdC === 0"></Text0>
          <Text1 @rotateTo="rotateTo" v-if="currentTopicIdC === 1"></Text1>
          <Text2 @rotateTo="rotateTo" v-if="currentTopicIdC === 2"></Text2>
          <Text3 @rotateTo="rotateTo" v-if="currentTopicIdC === 3"></Text3>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoButton from "@/components/InfoButton";
import Text0 from "@/components/Text0";
import Text1 from "@/components/Text1";
import Text2 from "@/components/Text2";
import Text3 from "@/components/Text3";
/*import * as Vue from "@vue/compiler-dom";*/

export default {
  name: "InfoView",
  components:{
    InfoButton,
    Text0,
    Text1,
    Text2,
    Text3,
  },
  props: {
    infoViewP: Boolean,
    modelData: Object,
  },
  data() {
    return {
      currentTopicId: 0,
      dragStartX: Number,
      dragEndX: Number,
      dragging: Boolean,
    }
  },
  /*touch this again when you know hwo to use Vue.render()*/
  /*watch: {
    currentTopic: function(){

      setTimeout(function (){

        let rotations = document.getElementsByClassName('rotator')
        rotations.forEach(obj =>{
          obj.style.color = 'red';
          let x = getComputedStyle(obj).getPropertyValue("--x")
          let y = getComputedStyle(obj).getPropertyValue("--y")
          let z = getComputedStyle(obj).getPropertyValue("--z")
          let o = getComputedStyle(obj).getPropertyValue("--o")

          function rotateToo(x, y, z, o) {
            this.rotateTo(x, y, z, o)
          }

          obj.addEventListener('click', rotateToo(x,y,z,o))
          /!*console.log( getComputedStyle(obj).getPropertyValue("--my-custom-property"))*!/
        })
        console.log(rotations);
      }, 10)
    }
  },*/
  computed: {
    currentTopicIdC: {
      get : function (){
        return this.currentTopicId;
      }
    },
    /*Irgendwo hier drinnen geht irgendwas falsch sobald der aktuelle zu 0 wird versucht der den previous falsch zu berechnen warum weiß ich allerdings noch nicht die modulo sachen müssen eigentlich passen....*/
    currentTopic: {
      get: function (){
        /*this compile shti aint working like I want it to this is probably a solution https://harin76.medium.com/generate-vue-js-components-from-a-json-javascript-dom-structure-a76534478d15*/
        /*return Vue.compile(this.modelData.texts[this.currentTopicId].text)*/
        if(this.modelData !== null) {
          return this.modelData.texts[this.currentTopicId].text
        }
        return null
      },
      set: function (id){
          this.currentTopicId = (id + this.modelData.texts.length)% this.modelData.texts.length;
      },
    },
    /*there is probably a better way of doing this but this works right now*/
    previousHeader: {
      get: function () {
        return (this.currentTopicId + this.modelData.texts.length-1) % this.modelData.texts.length
        /*return this.modelData.texts[(this.currentTopicId + 6) % this.modelData.texts.length].header*/
      },
    },
    currentHeader: {
      get: function () {
        /*return (this.currentTopicId + this.modelData.texts.length) % this.modelData.texts.length*/
        return this.modelData.texts[(this.currentTopicId + this.modelData.texts.length) % this.modelData.texts.length].header
      },
    },
    nextHeader: {
      get: function () {
        return (this.currentTopicId + this.modelData.texts.length+1) % this.modelData.texts.length
        /*return this.modelData.texts[(this.currentTopicId + 1) % this.modelData.texts.length].header*/
      },
    }
  },
  methods: {

    doStartDrag(event){
      this.dragStartX = event.targetTouches[0].pageX;
      this.dragging = true;
    },
    doDrag(event){
      if(this.dragging){
        this.dragEndX = event.targetTouches[0].pageX;
        let topics = document.getElementById("current")
        if(this.dragEndX > window.innerWidth * 0.5 && this.dragEndX - this.dragStartX > window.innerWidth * 0.2 && !topics.classList.contains('left')){
          topics.classList.add('left');
          topics.classList.remove('right')
        }else if(this.dragEndX < window.innerWidth * 0.5 && this.dragEndX - this.dragStartX < window.innerWidth * -0.2 && !topics.classList.contains('right')){
          topics.classList.add('right');
          topics.classList.remove('left')
        }
      }
    },
    doStopTouch(){

      if ( this.dragEndX - this.dragStartX > window.innerWidth * 0.2){
        this.currentTopic = this.currentTopicId - 1;
      } else if ( this.dragStartX - this.dragEndX > window.innerWidth * 0.2 && this.dragEndX !== 0){

        this.currentTopic  = this.currentTopicId +1;
      }
      this.dragging = false;
      this.dragEndX = 0;
      this.dragStartX = 0;

      let topics = document.getElementById("current")
      topics.classList.remove('left')
      topics.classList.remove('right')
    },
    rotateTo(x, y, z, o){
      this.$emit('rotateTo', x, y, z, o)
    }
  },
  created() {
    let rotations = document.getElementsByClassName('rotator')
    rotations.forEach((obj) =>{
      obj.style.color = "red";
    })
  },
  emits: ['toggleInfo', 'rotateTo'],
};
</script>

<style scoped>

/*the entire window including button and Text*/
.info__window{
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 98;
  display: flex;
  width: 100%;
  height: 0;
  justify-content: flex-end;
  overflow: hidden;
  transition: background-color 0s, height 1s;
}
/*activates when the button is clicked*/
.info__window.active{
  height: 50vh;
  justify-content: space-between;
  max-width: 100vw;

  transition: background-color 0s, height 1s;

  background-color: var(--backroundColor);

}

/*The header or name of the Object*/
h1{
  font-size: var(--h1-size);

}
/*the black block in front of the Object name*/
h1::before{
  width: var(--h1-size);
  height: var(--h1-size);
  background-color: var(--font-color);
  display: inline-block;
  position: relative;
  content: "";
  margin-right: 0.5rem;
}
/*TODO: need to put an after in here to make the top bar*/

/*The Subtitle aka Producer or whatever*/
h2{
  font-size: var(--topic-size);
  text-align: right;
}
/*the "navbar" on the right*/
.icon__infoSwitch{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 15%;
  position: absolute;
  z-index: 99;
  bottom: 0;
  right: 0;
}
.icon__infoSwitch  * {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}
.infoButton{
padding: 0.5rem;
  background-color: var(--backroundColor);

}
.currentInfo{
  border: 1rem;
  border-radius: 50%;
  border-top-left-radius: 1rem;
}
/*the actual text field*/
.info__text{
  padding: 0.8rem .8rem 0 0.8rem;
  transition: 1s;
}
.info__text.active{
  width: 100%;
  height: 100%;
}
/*the individual headers of the texts*/
.topics{
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  overflow: hidden;
  align-items: baseline;
  margin: 0.5rem 0 0rem 0;
  height: 2rem;
}
.topics *{
  display: none;
}
.topics p.secondary {
  color: var(--dimmed-font-color);

  display: inline-block;
  font-size: var(--secondary-topic-size);
}
.topics p.current {
  display: inline-block;
  font-size: var(--topic-size);
}
topics * ~ * {
  margin-left: 1rem;
}

.currentText{
  font-size: var(--text-size);

}
.scroll{
  overflow: scroll hidden;
}

#previous, #next{
  text-align: center;
  width: var(--topic-size);
  height: var(--topic-size);
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;

  display: inline-block;
  border: var(--linewidth) solid #333;
  border-radius: 50%;
}
#previous:after{
  content: '';
  display: inline-block;
  width: 0.3em;
  height: 0.3em;
  margin-top: -0.2em;
  margin-bottom: 0.175em;
  margin-left: 0.15em;
  border-top: var(--linewidth) solid #333;
  border-right: var(--linewidth) solid #333;
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
#next:after{
  content: '';
  display: inline-block;
  width: 0.3em;
  height: 0.3em;
  margin-left: -0.15em;
  margin-top: -0.2em;
  margin-bottom: 0.175em;
  border-top: var(--linewidth) solid #333;
  border-right: var(--linewidth) solid #333;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.category__visualisation{
  width: 100%;
  flex-direction: row;
  height: 0.2rem;
  display: flex;
  margin-bottom: 0.5rem;
}
.category__visualisation div{
  width: 0.2rem;
  display: flex;
  flex-grow: 4;
  flex-shrink: 4;
  height: 100%;
  background-color: #bababa;
}
.category__visualisation div + div{
  margin-left: 0.2rem;

}
.category__visualisation div.current__visual{
  background-color: var(--font-color);

}
#current{
  transition: 0.8s;
  transition-delay: 0.1s;
}
#current.right{
  margin-left: 2rem;
  transition-delay: 0s;
}
#current.left{
  margin-left: -2rem;
  transition-delay: 0s;
}
.empty{
  height: 50vh;
  width: 1vw;
}
</style>
