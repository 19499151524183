<template>
  Das Bauhaus war fasziniert von einfachen geometrischen Formen wie Kugel, Zylinder und Würfel.
  Mit diesen Grundelementen wollte man eine neue Welt aufbauen: reduziert, klar, geordnet.
  Auch Wagenfelds Tischleuchte zeigt diese Faszination. Die Kugelform begegnet uns in der <span class="rotation" @click="rotateTo(2.644, 2.809, -0.3, 1.995)">Opalglaskuppel</span>,
  am Ende der Zugschnur und sogar <span class="rotation" @click="rotateTo(1.065, -1.992, -0.111, 0.181)">unter der Fußplatte</span>. Neben dem
  <span class="rotation"  @click="rotateTo(2.794, 2.810, 0.1774, 0.857)">zylinderförmigen Schaft</span> gibt es auch Kreiselemente
  wie den Auflagering oder die runde Fussplatte.  Doch Wagenfeld reiht nicht nur ein geometrisches Element an das andere,
  sondern er bringt die Teile in perfekte Harmonie: Die Durchmesser des Auflagerings reagiert auf die Größe der Fußplatte
  und die Halbkugeln unter der Fußplatte lassen die Leuchte schweben.
</template>

<script>
export default {
name: "Text2",
  methods: {
    rotateTo(x, y, z, o){
      this.$emit('rotateTo', x, y, z, o)
    }
  }
}
</script>

<style scoped>

</style>