<template>
  <Ar v-show="!infoView"></Ar>
  <div v-show="infoView" id="container" ></div>
</template>

<script>
 import * as THREE from "three"
/* import Stats from 'stats.js'*/
 import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
 import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
 import Ar from "@/components/Ar";


export default {
  name: "ObjectView",
  components:{
    Ar
  },
  data() {
    return {
      dragging: false,
      oldMousePositionX: 0,
      oldMousePositionY: 0,
      fov: 45,
      aspect: 2,
      near: 0.1,
      far: 10000,
      transitionTime: 120,

      forceRotating: false,
      increment: {
        x: 0,
        y: 0,
        z: 0,
        o: 0,
        o1: 0,
        o2: 0,
        current: 0,
      },
    }
  },
  props: {
    model: String,
    rotation: [],
    zoom: [],
    infoView: Boolean,
    forceRotationX: Number,
    forceRotationY: Number,
    forceRotationZ: Number,
    forceRotationO: Number,

    forceRotatingP: {
      type: Boolean,
      default: false,
    },

  },
  watch:{
    infoView: function (){
      if(this.infoView){
        this.render()
      }
    },/*
    forceRotationX: function (){
      this.forceRotating = true;
    },
    forceRotationY: function (){
      this.forceRotating = true;
    },
    forceRotationZ: function (){
      this.forceRotating = true;
    },
    forceRotationO: function (){
      this.forceRotating = true;
    },*/
    forceRotatingP: function (){
      this.forceRotating = this.forceRotatingP
      let cam = this.camera.position;
      this.increment.x = (this.forceRotationX - cam.x)/this.transitionTime;
      this.increment.y = (this.forceRotationY - cam.y)/this.transitionTime;
      this.increment.z = (this.forceRotationZ - cam.z)/this.transitionTime;
      this.increment.o = (this.forceRotationO - this.orbitControls.target.y)/this.transitionTime;
      this.increment.o1 = (0 - this.orbitControls.target.x)/this.transitionTime;
      this.increment.o2 = (0 - this.orbitControls.target.z)/this.transitionTime;

    },
  },
  methods: {
    init() {

      this.scene = new THREE.Scene();
      this.clock = new THREE.Clock();
      this.camera = new THREE.PerspectiveCamera(
          this.fov,
          window.innerWidth/window.innerHeight,
          this.near,
          this.far,
      );

      this.renderer = new THREE.WebGLRenderer({antialias: true});
      this.gltfLoader = new GLTFLoader();
      this.orbitControls = new OrbitControls(
          this.camera,
          this.renderer.domElement
      );
      this.animationMixer = new THREE.AnimationMixer(this.scene);

      this.renderer.setSize(window.innerWidth, window.innerHeight);

      document.getElementById('container').appendChild(this.renderer.domElement);
      window.addEventListener('resize', () => this.onWindowResize());

      /*{ x: 3.913278859418291, y: 1.6088575954468747, z: 3.6820125634939593 }*/
      this.camera.position.set(0, 1, 5.6820125634939593);
      /*TODO: Probably need to disable this later on*//*
      this.scene.add(new THREE.GridHelper(10,10));*/

      this.gltfLoader.load(
          /*${process.env.BASE_URL}*/
          '/model/Lamp_2.gltf',
          gltf => {
            gltf.scene.name = 'lamp';
            gltf.scene.rotation.y = Math.PI;
            gltf.scene.scale.set(0.005, 0.005, 0.005);
            this.activeObject = gltf.scene;
            this.scene.add(gltf.scene);
            this.treeData = [gltf.scene];

            this.orbitControls.target.set(0, this.forceRotationY, 0);
          }
      );

      this.scene.background = new THREE.Color( 0x0703E0)
      this.setLights();
      this.render();
    },
    onMouseMove(event){
      this.activeObject.rotation.y += event.movementY * 0.005;
      this.activeObject.rotation.x += event.movementX * 0.005;
    },
    render(){
      /*only renders the frame when the info vies is supposed to show*/
      if(this.infoView){
        /*changes the camera dependent on the view state somewhat smooth*/
        if (this.activeObject != null && !this.infoView && this.activeObject.position.y > -0.3) {
          this.activeObject.position.y -= 0.02 * (this.activeObject.position.y + 0.4);
        } else if (this.activeObject != null && this.infoView && this.activeObject.position.y < 1.1) {
          this.activeObject.position.y += 0.02 * (1.2 - this.activeObject.position.y);
        }

        this.animationMixer.update(this.clock.getDelta());
        this.renderer.render(this.scene, this.camera);
        if(this.forceRotating){
          let cam = this.camera.position;
          cam.x += this.increment.x;
          cam.y += this.increment.y;
          cam.z += this.increment.z;
          this.orbitControls.target.y += this.increment.o;
          this.orbitControls.target.x += this.increment.o1;
          this.orbitControls.target.z += this.increment.o2;
          this.increment.current +=1;
          if(this.increment.current === this.transitionTime){
            this.$emit('forceRotationDone')
            this.forceRotating = false;
            this.increment.current = 0;
          }
        }
        this.orbitControls.update();
        /*console.log("cam")
        console.log(this.camera.position)
        console.log("orbit")
        console.log(this.orbitControls.target)*/
        window.requestAnimationFrame(() => this.render())
        /*this.stats.update();*/
      }
    },

    onWindowResize(){
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

    },

    setLights(){
      const light = new THREE.DirectionalLight( 0xffffff, 0.5, );
      light.position.set( 200, 5, 5 );
      light.castShadow = true;
      this.scene.add( light );
      this.scene.add( light.target );

      const light2 = new THREE.DirectionalLight( 0xffffff, 0.5, );
      light2.position.set( -200, 5, 5 );


      light2.castShadow = true;
      this.scene.add( light2 );

      const light3 = new THREE.DirectionalLight( 0xffffff, 0.5,);
      light3.position.set( 5, 200, 5 );
      light3.castShadow = true;
      this.scene.add( light3 );

      const light4 = new THREE.DirectionalLight( 0xffffff, 0.5,);
      light3.position.set( 5, -200, 5 );
      light3.castShadow = true;
      this.scene.add( light4 );

      this.scene.add(new THREE.AmbientLight(0xffffff, 1.5));
    },

    toRadians: function(angle){
      return angle*(Math.PI / 180);
    },
    /*startDrag(){
      this.dragging = true;

      console.log(this.dragging);
    },
    doDrag: function (event){/!*
      console.log("Pascal ist ne kleine bitch")
      console.log(this.oldMousePositionX);
      console.log(this.oldMousePositionY);*!/
      if(this.dragging){
        /!*calculate the different to the last rotation point*!/

        let deltaMove = {
          x: (event.targetTouches[0].pageX /!*- event.touches[0].target.offsetLeft*!/) - this.oldMousePositionX,
          y: (event.targetTouches[0].pageY /!*- event.touches[0].target.offsetTop*!/) - this.oldMousePositionY
        };
        console.log(deltaMove.x + "x" + deltaMove.y + "y" + "this is the delta move");

        /!*check if the user is currently holding the mouse down*!/
        if(this.dragging){
          let deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(
              this.toRadians(deltaMove.y * -0.2),
              this.toRadians(deltaMove.x * -0.2),
              0,
              'XYZ'
          ));

          this.activeObject.quaternion.multiplyQuaternions(deltaRotationQuaternion, this.activeObject.quaternion);
        }

        this.oldMousePositionX = event.targetTouches[0].pageX - event.touches[0].target.offsetLeft;
        this.oldMousePositionY = event.targetTouches[0].pageY - event.touches[0].target.offsetTop;
      }
    },
    stopDrag(){
      this.dragging = false;
    },*/


    /*
    init: function (){
      this.canvas = document.getElementById('container');

      this.renderer = new Three.WebGLRenderer({this.canvas});

      this.camera = new Three.PerspectiveCamera(this.fov, this.aspect, this.near, this.camera.position.set(0, 10, 20));

      this.controls = new OrbitControls(this.camera, this.canvas);
      this.controls.target.set(0, 5, 0);
      this.controls.update();

      this.scene = new Three.Scene();
      this.scene.background = new Three.Color('back');

      /!*example texturing and object*!/
      const planeSize = 40;

      const loader = new Three.TextureLoader();
      const texture = loader.load('https://threejsfundamentals.org/threejs/resources/images/checker.png');
      texture.wrapS = Three.RepeatWrapping;
      texture.wrapT = Three.RepeatWrapping;
      texture.magFilter = Three.NearestFilter;
      const repeats = planeSize / 2;
      texture.repeat.set(repeats, repeats);

      const planeGeo = new Three.PlaneGeometry(planeSize, planeSize);
      const planeMat = new Three.MeshPhongMaterial({
        map: texture,
        side: Three.DoubleSide,
      });
      const planeMesh = new Three.Mesh(planeGeo, planeMat);
      mesh.rotation.x = Math.PI * -.5;
      this.scene.add(planeMesh);


      const model = loader.load('https://threejsfundamentals.org/threejs/resources/models/cartoon_lowpoly_small_city_free_pack/scene.gltf', function(gltf){
        const root = gltf.scene;
        this.scene.add(root);

        const box = new Three.Box3().setFromObject(root);

        const boxSize = box.getSize(new Three.Vector3()).length();
        const boxCenter = box.getCenter(new Three.Vector3());


      })
    },
    frameArea : function(sizeToFitScreen, boxSize, boxCenter, camera){
      const halfSizeToFitScreen = sizeToFitScreen * 0.5;
      const halfFovY = Three.MathUtils.degToRad(camera.fov * 0.5);
      /!*why ever this line does what it does but it sure looks smart*!/
      const distance = halfSizeToFitScreen/ Math.tan(halfFovY);

      const direction = (new Three.Vector3()).subVectors(camera.position, boxCenter).multiply(new Three.Vector3(1, 0, 1)).normalize();

      /!*move the camera to a position distance units way from the center
        in whatever direction the camera was from the center already *!/
      camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

      camera.near = boxSize / 100;
      camera.far = boxSize * 100;

      camera.updateProjectionMatrix();

      camera. lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
    },
*/
    /*
    /!*createCamera: function(){
      this.camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.01, 30);
      this.camera.position.z = 1;
    },*!/


    init: function () {
      const loader = new GLTFLoader();

      let container = document.getElementById('container');



      this.scene = new Three.Scene();
      this.camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.01, 30);
      this.camera.position.z = 1;
      /!*this.createCamera();
*!/
      /!*let geometry = new Three.BoxGeometry(0.1, 0.1, 0.1);
      let material = new Three.MeshBasicMaterial({ color: 0x00ff00 });

      this.mesh = new Three.Mesh(geometry, material);*!/
      loader.load('https://threejsfundamentals.org/threejs/resources/models/cartoon_lowpoly_small_city_free_pack/scene.gltf', function(gltf){
        console.log(gltf)
        this.mesh = gltf;
        this.scene.add(gltf);
      });

      this.renderer = new Three.WebGLRenderer({antialias: true});
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      container.appendChild(this.renderer.domElement);
    },
    /!*basic rotation for the basic scene*!/
    animate: function (){
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);

    },

    /!*Entering dragging mode*!/
    startDrag: function() {
      this.dragging = true;
      console.log(this.dragging);
    },
    stopDrag: function (){
      this.dragging = false;
      console.log(this.dragging);
    },


    toRadians: function(angle){
      return angle*(Math.PI / 180);
    },
    doDrag: function (event){
      if(this.dragging){
        /!*calculate the different to the last rotation point*!/
        let deltaMove = {
          x: event.offsetX - this.oldMousePosition.x,
          y: event.offsetY - this.oldMousePosition.y
        };
        console.log(deltaMove);

        /!*check if the user is currently holding the mouse down*!/
        if(this.dragging){
          let deltaRotationQuaternion = new Three.Quaternion().setFromEuler(new Three.Euler(
              this.toRadians(deltaMove.y * -0.7),
              this.toRadians(deltaMove.x * -0.7),
              0,
              'XYZ'
          ));

          this.mesh.quaternion.multiplyQuaternions(deltaRotationQuaternion, this.mesh.quaternion);
        }

        this.oldMousePosition = {
          x: event.offsetX,
          y: event.offsetY
        };
      }
    },
*/
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
#container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>
