<template>
  Wagenfeld sagt, dass er sich „1924 erstmals in [s]einem Leben mit einem Gegenstand beschäftigte,
  der voll und ganz in unsere Zeit gehört.“ Und zu dieser zukunftsfreudigen Zeit gehört sogar
  Elektrizität! In den 1920er Jahren waren elektrische Geräte im Haushalt noch nicht selbstverständlich
  und so betont der junge Wagenfeld konstruktive Details wie beispielsweise das <span class="rotation" @click="rotateTo(1, 2.269,0.164, 1.941)">Schraubgewinde</span> für die Fassung
  der Glühlampe. Das war ein mutiger Schritt, denn bisher wurden technische Details von Alltagsprodukten eher
  versteckt. Daraus spricht nicht nur die Begeisterung für Technik, sondern auch ein feines Formgefühl: Die
  Gewinderinge vermitteln optisch zwischen dem Schaft und der Glaskuppel. Auf diese Weise bekommt sie einen leichten,
  fast schwebenden Charakter.
</template>

<script>
export default {
name: "Text1",
  methods: {
    rotateTo(x, y, z, o){
      this.$emit('rotateTo', x, y, z, o)
    }
  }
}
</script>

<style scoped>

</style>