<template>
  Erst mit der Reedition der Bauhaus-Leuchte durch das Bremer Unternehmen Tecnolumen findet die Bauhausleuchte ab 1980
  ihre massenhafte Verbreitung. Mit 80 Jahren beteiligt sich Wilhelm Wagenfeld an der
  Überarbeitung seines Entwurfs aus Jugendzeiten: In den Proportionen folgt die Leuchte dem Original
  von 1924. Zwei Elemente unterscheiden sich jedoch von der frühen Variante:  zum einen laufen die <span class="rotation" @click="rotateTo(-0.15, 1.99,-0.81, 2.04)">Stege
  des  Auflagerings</span>  nicht mehr gerade durch, sondern biegen sich am Ende in einem 90-Grad-Winkel.
  Zum anderen sorgen inzwischen Energiesparlampen für das sanfte Leuchten der Kuppel - das hat aber auch
  seine Vorteile: die Glühlampen der 1920er Jahren wurden so heiß, dass einige Leuchtenbesitzer sich neue
  Kuppeln bestellen mussten: die alten waren geplatzt.
</template>

<script>
export default {
name: "Text3",
  methods: {
    rotateTo(x, y, z, o){
      this.$emit('rotateTo', x, y, z, o)
    }
  }
}
</script>

<style scoped>

</style>