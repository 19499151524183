<template>
  <div class="logo__text">
    <div id="logo__window" :style="{width: size}" class="logo__window" >
      <svg  width="100%" viewBox="-20 0 100 100">
        <rect width="62.22" height="99.55" rx="3.11"/><rect class="cls-1" x="6.22" y="6.22" width="49.78" height="80.89" rx="3.11"/>
        <rect class="cls-1" x="21.6" y="90.22" width="18.67" height="6.22" rx="1.5"/>
        <path v-show="!infoView" class="cls-2" d="M22.09,75.08V72.64c5.49-.53,6.1-1,6.1-6.89V47.81c0-5.4-.26-5.75-5.49-6.62V39.1a57.17,57.17,0,0,0,12.37-3.49V65.75c0,5.93.61,6.36,6.28,6.89v2.44Zm9.06-48a4.77,4.77,0,0,1-4.61-4.62,4.7,4.7,0,0,1,4.7-4.79,4.54,4.54,0,0,1,4.44,4.79A4.63,4.63,0,0,1,31.15,27.08Z"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoButton",
  props:{
    infoViewP: Boolean,
    size: String,
  },
  data() {
    return {
      infoView: false,
    }
  },
  watch: {
    infoViewP: function (newVal){
      this.infoView = newVal;
    }
  },
}
</script>

<style scoped>
 .cls-1{
   fill: var(--backroundColor);
 }
 .cls-2{
   fill: #caca3b;
 }
</style>