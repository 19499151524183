<template>
  <router-view :allData="models" :modelData="models" />
</template>
<script>
import allModels from "./Data/dummyData";
export default {
  data() {
    return {
      models: allModels,
    };
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  transition: 1s;
}
html{
  /*defining colors for all the css stuff*/
  --backroundColor: #f8f8f8;
  --font-color: #171b21;
  --dimmed-font-color: #c3c3c3;
  --3DLink-color: #080896;

  /*Font-Sizes*/
  --h1-size: 1.3rem;
  --topic-size: 1.15rem;
  --secondary-topic-size: 1rem;
  --text-size: 1rem;

  /*sizes for the arrows*/
  --linewidth: 0.125rem;
}

#app {
  font-family: Tahoma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  line-height: 1.24rem;
  max-width: 100vw;
}

.rotation{
  color: var(--3DLink-color);
}
</style>
